<template>
  <div>
    <el-breadcrumb style="margin: 0 0 6px 6px" separator="/">
      <!-- <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>资源管理</el-breadcrumb-item>
      <el-breadcrumb-item>资源列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <!-- <div class="tt">入库管理</div>
      <div class="xian"></div>-->
      <div class="list_box">
        <div class="tt1">资源列表</div>
        <div>
          <el-button
            size="mini"
            type="cha"
            icon="el-icon-search"
            @click="chaxun"
            >查询</el-button
          >
          <el-button
            type="add"
            size="mini"
            icon="el-icon-circle-plus-outline"
            @click="add"
            >添加</el-button
          >
        </div>
      </div>
      <div class="int_box">
        <div class="int_box_a">
          <span>资源名称：</span>
          <el-input
            size="small"
            class="int_width"
            placeholder="请输入资源名称"
            v-model="resources_name"
            clearable
          ></el-input>
        </div>
        <div class="int_box_a">
          <span>资源类型：</span>
          <el-select
            size="small"
            class="int_width"
            v-model="resources_type1"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="(value, name) in type"
              :key="name"
              :label="value"
              :value="name"
            >
            </el-option>
          </el-select>
        </div>
        <div class="int_box_a">
          <span>难度：</span>
          <el-select
            size="small"
            class="int_width"
            v-model="difficulty1"
            clearable
            placeholder="请选择"
          >
            <el-option
              v-for="(value, name) in difficulty"
              :key="name"
              :label="value"
              :value="name"
            >
            </el-option>
          </el-select>
        </div>
      </div>
      <template>
        <el-table
          :header-cell-style="tableHeader"
          :data="indexList"
          style="width: 100%"
          stripe
        >
          <el-table-column
            type="index"
            label="序号"
            width="60"
          ></el-table-column>
          <el-table-column
            prop="resources_name"
            label="资源名称"
          ></el-table-column>
          <el-table-column
            width="180"
            prop="resources_no"
            label="资源编号"
          ></el-table-column>
          <el-table-column label="缩略图">
            <template v-slot="scope">
              <img
                class="list_img"
                :src="URL_img + scope.row.resources_cover_pic"
                alt=""
              />
            </template>
          </el-table-column>
          <el-table-column label="费用类型">
            <template v-slot="scope">
              <div>{{ scope.row.foreign_type == 1 ? '公开' : '免费' }}</div>
            </template>
          </el-table-column>
          <el-table-column label="资源类型">
            <template v-slot="scope">
              <div>{{ type[scope.row.resources_type] }}</div>
            </template>
          </el-table-column>
          <el-table-column label="媒体类型">
            <template v-slot="scope">
              <div>{{ document_type[scope.row.document_type] }}</div>
            </template>
          </el-table-column>
          <el-table-column label="难度">
            <template v-slot="scope">
              <div>{{ difficulty[scope.row.difficulty] }}</div>
            </template>
          </el-table-column>
          <el-table-column label="禁用 / 启用">
            <template v-slot="scope">
              <el-switch
                @change="switchChange(scope.row)"
                v-model="scope.row.is_disable"
                active-color="#13ce66"
                inactive-color="#ff4949"
                :active-value="0"
                :inactive-value="1"
              >
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column
            width="150"
            prop="add_date"
            label="创建时间"
          ></el-table-column>
          <el-table-column width="210" label="操作">
            <template v-slot="scope">
              <el-tooltip
                :enterable="false"
                effect="dark"
                content="编辑"
                placement="top-end"
              >
                <el-button
                  size="mini"
                  type="primary"
                  icon="iconfont icon-bianxie"
                  @click="edit(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip
                :enterable="false"
                effect="dark"
                content="删除"
                placement="top-end"
              >
                <el-button
                  size="mini"
                  type="danger"
                  icon="iconfont icon-shanchu"
                  @click="del(scope.row.resources_no, scope.$index)"
                ></el-button>
              </el-tooltip>
              <el-button
                size="mini"
                type="warning"
                @click="goxianzhi(scope.row)"
                >限制列表</el-button
              >
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 15, 30, 50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <el-dialog
        :title="text"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="1000px"
        :before-close="handleClose"
      >
        <div class="xian2"></div>
        <div style="max-height: 500px; overflow-y: auto">
          <el-form
            ref="form"
            :model="addform"
            :rules="rules"
            label-width="140px"
          >
            <el-form-item label="资源名称：" prop="name">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.name"
                placeholder="请输入资源名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="费用类型：">
              <el-radio-group
                v-model="addform.foreign_type"
                @change="typeChange"
              >
                <el-radio :label="1">公开</el-radio>
                <el-radio :label="2">收费</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-form-item label="资源类型：">
              <el-select
                size="small"
                style="width: 450px"
                v-model="addform.resources_type"
                placeholder="请选择"
              >
                <el-option
                  v-for="(value, name) in type"
                  :key="name"
                  :label="value"
                  :value="name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="难度：">
              <el-select
                size="small"
                style="width: 450px"
                v-model="addform.difficulty"
                placeholder="请选择"
              >
                <el-option
                  v-for="(value, name) in difficulty"
                  :key="name"
                  :label="value"
                  :value="name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="媒体类型：">
              <el-select
                size="small"
                style="width: 450px"
                v-model="addform.document_type"
                placeholder="请选择"
              >
                <el-option
                  v-for="(value, name) in document_type"
                  :key="name"
                  :label="value"
                  :value="name"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="上传资源：" prop="resources_url">
              <el-upload
                class="upload-demo"
                style="width: 450px"
                :with-credentials="true"
                :action="URL + 'edulogin.php/ajax/upload'"
                :on-remove="handleRemove"
                :on-success="handleSuccess"
                :before-upload="beforeAvatarUpload1"
                :limit="1"
                :on-exceed="handleExceed"
                :file-list="fileList"
              >
                <el-button size="small" type="primary">点击上传</el-button>
              </el-upload>
            </el-form-item>
            <el-form-item label="资源时长(分钟)：" prop="resources_duration">
              <el-input
                type="number"
                @focus="durationFocus"
                @blur="durationBlur"
                size="small"
                style="width: 450px"
                v-model="addform.resources_duration"
                placeholder="请输入资源时长"
                clearable
              ></el-input>
            </el-form-item>

            <el-form-item
              label="收费金额(元)："
              v-if="addform.foreign_type == 2"
            >
              <el-input
                @focus="charge_numFocus"
                @blur="charge_numBlur"
                type="number"
                size="small"
                style="width: 450px"
                v-model="addform.charge_num"
                placeholder="请输入收费金额"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="资源缩略图：" prop="resources_cover_pic">
              <el-upload
                class="avatar-uploader"
                :action="URL + 'edulogin.php/ajax/upload'"
                :show-file-list="false"
                :with-credentials="true"
                :before-upload="beforeAvatarUpload"
                :on-success="handleAvatarSuccess"
              >
                <img
                  v-if="addform.resources_cover_pic"
                  :src="URL_img + addform.resources_cover_pic"
                  class="avatar"
                />
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </el-form-item>
            <el-form-item label="资源介绍：">
              <el-input
                size="small"
                style="width: 450px"
                type="textarea"
                :autosize="{ minRows: 4, maxRows: 6 }"
                v-model="addform.resources_content"
                placeholder="请输入资源介绍"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-button v-if="id" type="cha" class="add_btn" @click="edit_btn"
          >确定</el-button
        >
        <el-button v-else type="cha" class="add_btn" @click="add_btn"
          >确定</el-button
        >
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  inject: ['reload'],
  data() {
    return {
      page: 1,
      limit: 10,
      difficulty1: '',
      resources_type1: '',
      resources_name: '',

      indexList: [],
      total: 0,
      tableHeader: {
        'background-color': '#EEF1F6',
        'border-top': '2px solid #1CA6C8',
        color: '#333',
      },

      dialogVisible: false,
      addform: {
        name: '',
        foreign_type: 1,
        resources_url: '',
        resources_duration: 0,
        resources_type: '1',
        charge_num: '',
        resources_cover_pic: '',
        no: '',
        difficulty: '1',
        size: '',
        document_type: '1',
        resources_content: '',
      },

      rules: {
        name: [{ required: true, message: '请输入资源名称', trigger: 'blur' }],
        resources_url: [
          { required: true, message: '请选择上传资源', trigger: 'blur' },
        ],
        resources_duration: [
          { required: true, message: '请输入资源时长', trigger: 'blur' },
        ],
        charge_num: [
          { required: true, message: '请输入收费金额', trigger: 'blur' },
        ],
        resources_cover_pic: [
          { required: true, message: '请选择资源缩略图', trigger: 'blur' },
        ],
      },
      id: '',
      text: '',
      URL: '',
      URL_img: '',
      token: '',
      fileList: [],
      categoryList: [],
      type: {},
      difficulty: {},
      document_type: {},
    }
  },
  mounted() {
    this.index()
    this.sourcetype()
    this.URL = this.$URL
    this.URL_img = this.$URL_img
  },
  methods: {
    handleSizeChange(val) {
      this.limit = val
      this.index()
    },
    handleCurrentChange(val) {
      this.page = val
      this.index()
    },
    handleClose() {
      this.id = ''
      this.dialogVisible = false
      this.$refs.form.resetFields()
      this.addform = {
        name: '',
        foreign_type: 1,
        resources_url: '',
        resources_duration: 0,
        resources_type: '1',
        charge_num: '',
        resources_cover_pic: '',
        no: '',
        difficulty: '1',
        size: '',
        document_type: '1',
        resources_content: '',
      }
      this.fileList = []
    },
    // __token__令牌
    async __token__() {
      const { data: res } = await this.$http.get(
        'index.php/api/index/formtoken'
      )
      if (res.code !== 1) return this.$message.error(res.msg)
      this.token = res.data.info
    },
    // 列表
    async index() {
      const { data: res } = await this.$http.get(
        'edulogin.php/resources/resources/index',
        {
          params: {
            page: this.page,
            limit: this.limit,
            resources_name: this.resources_name,
            difficulty: this.difficulty1,
            resources_type: this.resources_type1,
          },
        }
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.indexList = res.data.list
      this.total = res.data.total
    },

    // 查询
    chaxun() {
      this.page = 1
      this.index()
    },
    // 资源类型和难度 /edulogin.php/resources/resources/sourcetype
    async sourcetype() {
      const { data: res } = await this.$http.get(
        'edulogin.php/resources/resources/sourcetype'
      )
      if (res.code != 1) return this.$message.error(res.msg)
      this.type = res.data.type
      this.difficulty = res.data.difficulty
      this.document_type = res.data.document_type
    },

    handleAvatarSuccess(res, file) {
      if (res.code != 1) return this.$message.error(res.msg)
      this.addform.resources_cover_pic = res.data.url
    },
    // 限制用户上传的图片格式和大小
    beforeAvatarUpload1(file) {
      console.log(file)
      if (!this.addform.resources_type) {
        this.$message.error('请选择资源类型！')
        return false
      }
      console.log(4, file)
      const isMp4 = file.type === 'video/mp4'
      const isMp3 = file.type === 'audio/mpeg'
      if (
        this.addform.resources_type == 2 ||
        this.addform.resources_type == 3 ||
        this.addform.resources_type == 3
      ) {
        if (isMp3 || isMp4) {
          return isMp4 || isMp3
        } else {
          this.$message.error('请上传MP3,MP4格式!')
          return false
        }
      }
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isLt2M) {
        this.$message.error('上传图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    // 上传资源
    handleRemove(file, fileList) {
      this.addform.resources_url = ''
      this.addform.size = ''
    },
    handleSuccess(res, file) {
      if (res.code != 1) {
        this.fileList = []
        return this.$message.error(res.msg)
      }
      this.addform.resources_url = res.data.url
      this.addform.size = res.data.size
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    // 资源时长获取焦点
    durationFocus() {
      if (this.addform.resources_duration == 0) {
        this.addform.resources_duration = ''
      }
    },
    // 资源时长失去焦点
    durationBlur() {
      if (this.addform.resources_duration == '') {
        this.addform.resources_duration = 0
      }
    },
    // 收费金额获取焦点
    charge_numFocus() {
      if (this.addform.charge_num == 0) {
        this.addform.charge_num = ''
      }
    },
    // 收费金额失去焦点
    charge_numBlur() {
      if (this.addform.charge_num == '') {
        this.addform.charge_num = 0
      }
    },

    // 费用类型变化
    typeChange(e) {
      this.addform.charge_num = 0
    },
    // 跳转到限制列表
    goxianzhi(row) {
      window.localStorage.setItem('jxzong_activePath', '/restriction')
      this.$router.push('/restriction?resources_no=' + row.resources_no)
      this.reload()
    },
    // 点击添加按钮，弹出添加弹出框
    add() {
      this.text = '添加'
      this.dialogVisible = true
      this.sourcetype()
      this.__token__()
    },

    // 添加确认
    add_btn() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整的数据')
        this.addform.__token__ = this.token
        const { data: res } = await this.$http.post(
          'edulogin.php/resources/resources/add',
          this.addform
        )
        if (res.code != 1) {
          this.token = res.data.__token__
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.dialogVisible = false
        this.$refs.form.resetFields()
        ;(this.addform = {
          name: '',
          foreign_type: 1,
          resources_url: '',
          resources_duration: 0,
          resources_type: '1',
          charge_num: '',
          resources_cover_pic: '',
          no: '',
          difficulty: '1',
          size: '',
          document_type: '1',
          resources_content: '',
        }),
          (this.fileList = [])
        this.index()
      })
    },
    // 点击编辑
    edit(row) {
      this.text = '编辑'
      this.id = row.resources_no
      this.dialogVisible = true
      this.addform = {
        name: row.resources_name,
        foreign_type: row.foreign_type,
        resources_url: row.resources_url,
        resources_duration: row.resources_duration,
        resources_type: row.resources_type + '',
        charge_num: row.charge_num,
        resources_cover_pic: row.resources_cover_pic,
        no: row.resources_no,
        difficulty: row.difficulty + '',
        size: row.size,
        document_type: row.document_type + '',
        resources_content: row.resources_content,
      }
      if (row.resources_url) {
        this.fileList = [{ name: row.resources_url, url: row.resources_url }]
      }
      this.sourcetype()
      this.__token__()
    },
    // 编辑确认
    async edit_btn() {
      this.addform.__token__ = this.token
      const { data: res } = await this.$http.post(
        'edulogin.php/resources/resources/editresources',
        this.addform
      )
      if (res.code !== 1) {
        this.token = res.data.__token__
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.$refs.form.resetFields()
      this.dialogVisible = false
      ;(this.addform = {
        name: '',
        foreign_type: 1,
        resources_url: '',
        resources_duration: 0,
        resources_type: '1',
        charge_num: '',
        resources_cover_pic: '',
        no: '',
        difficulty: '1',
        size: '',
        document_type: '1',
        resources_content: '',
      }),
        (this.fileList = [])
      this.id = ''
      this.index()
    },
    // 禁用 / 启用
    async switchChange(row) {
      const { data: res } = await this.$http.post(
        'edulogin.php/resources/resources/disableresources',
        {
          resources_no: row.resources_no,
          isdisable: row.is_disable,
        }
      )
      if (res.code !== 1) {
        this.token = res.data.__token__
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.index()
    },
    // 删除
    del(resources_no, i) {
      this.$confirm('此操作将删除该资源, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(async () => {
          const { data: res } = await this.$http.post(
            'edulogin.php/resources/resources/delresources',
            {
              resources_no: resources_no,
            }
          )
          if (res.code !== 1) return this.$message.error(res.msg)
          this.indexList.splice(i, 1)
          this.$message.success(res.msg)
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除',
          })
        })
    },
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .tt {
    font-size: 24px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 30px auto;
  }
  .list_box {
    width: 100%;
    height: 38px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tt1 {
      font-size: 16px;
      padding: 2px 10px;
      border-left: 3px solid #1ca6c8;
    }
  }
  .int_box1 {
    padding: 0 1px 15px 1px;
  }
  .int_box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px 1px 0px;
  }
  .int_box_a {
    padding-bottom: 15px;
  }
  .int_width {
    width: 242px;
    margin-right: 40px;
  }
  span {
    color: #666;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 15px 0;
  }
  .xian2 {
    width: 100%;
    height: 2px;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
  .ttright {
    font-size: 14px;
    margin-left: 20px;
    color: #666;
  }
  .add_btn {
    width: 140px;
    margin: 40px 0 30px 420px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9 !important;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .list_img {
    width: 40px;
    height: 40px;
    display: block;
  }
}
</style>
