<template>
  <div>
    <el-breadcrumb style="margin: 0 0 6px 6px" separator="/">
      <!-- <el-breadcrumb-item :to="{ path: '/welcome' }">首页</el-breadcrumb-item> -->
      <el-breadcrumb-item>资源管理</el-breadcrumb-item>
      <el-breadcrumb-item>分类列表</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="content">
      <!-- <div class="tt">入库管理</div>
      <div class="xian"></div>-->
      <div class="list_box">
        <div class="tt1">分类列表</div>
        <div>
          <!-- <el-button
            size="mini"
            type="cha"
            icon="el-icon-search"
            @click="chaxun"
            >查询</el-button
          > -->
          <el-button
            type="add"
            size="mini"
            icon="el-icon-circle-plus-outline"
            @click="add"
            >添加</el-button
          >
        </div>
      </div>
      <!-- <div class="int_box">
        <div class="int_box_a">
          <span>名称：</span>
          <el-input
            size="small"
            class="int_width"
            placeholder="请输入名称"
            v-model="name"
            clearable
          ></el-input>
        </div>
      </div> -->
      <template>
        <el-table
          :header-cell-style="tableHeader"
          :data="indexList"
          style="width: 100%"
          stripe
        >
          <el-table-column prop="id" label="ID号" width="60"></el-table-column>
          <el-table-column prop="category_name" label="名称"></el-table-column>
          <el-table-column prop="remark" label="备注"></el-table-column>
          <el-table-column width="130px" label="操作">
            <template v-slot="scope">
              <el-tooltip
                :enterable="false"
                effect="dark"
                content="编辑"
                placement="top-end"
              >
                <el-button
                  size="mini"
                  type="primary"
                  icon="iconfont icon-bianxie"
                  @click="edit(scope.row)"
                ></el-button>
              </el-tooltip>
              <el-tooltip :enterable="false" effect="dark" content="删除" placement="top-end">
                <el-button
                  size="mini"
                  type="danger"
                  icon="iconfont icon-shanchu"
                  @click="del(scope.row.id)"
                ></el-button>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
      </template>
      <div class="pagination">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 15, 30, 50]"
          :page-size="limit"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        ></el-pagination>
      </div>
      <el-dialog
        :title="text"
        :close-on-click-modal="false"
        :visible.sync="dialogVisible"
        width="1000px"
        :before-close="handleClose"
      >
        <div class="xian2"></div>
        <div style="max-height: 500px; overflow-y: auto">
          <el-form
            ref="form"
            :model="addform"
            :rules="rules"
            label-width="140px"
          >
            <el-form-item label="分类名称：" prop="category_name">
              <el-input
                size="small"
                style="width: 450px"
                v-model="addform.category_name"
                placeholder="请输入分类名称"
                clearable
              ></el-input>
            </el-form-item>
            <el-form-item label="备注：">
              <el-input
                type="textarea"
                size="small"
                style="width: 450px"
                v-model="addform.remark"
                placeholder="请输入备注"
                clearable
              ></el-input>
            </el-form-item>
          </el-form>
        </div>
        <el-button v-if="id" type="cha" class="add_btn" @click="edit_btn"
          >确定</el-button
        >
        <el-button v-else type="cha" class="add_btn" @click="add_btn"
          >确定</el-button
        >
      </el-dialog>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      page: 1,
      limit: 10,
      name: '',
      indexList: [],
      total: 0,
      tableHeader: {
        'background-color': '#EEF1F6',
        'border-top': '2px solid #1CA6C8',
        color: '#333',
      },

      dialogVisible: false,
      addform: {
        category_name: '',
        remark: '',
        id: ''
      },

      rules: {
        category_name: [{ required: true, message: '请输入分类名称', trigger: 'blur' }]
      },
      id: '',
      text: '',
      URL: '',
      URL_img: '',
    }
  },
  mounted() {
    this.index()
    this.URL = this.$URL
    this.URL_img = this.$URL_img
  },
  methods: {
    handleSizeChange(val) {
      this.limit = val
      this.index()
    },
    handleCurrentChange(val) {
      this.page = val
      this.index()
    },
    handleClose() {
      this.id = ''
      this.dialogVisible = false
      this.$refs.form.resetFields()
      this.addform = {
        category_name: '',
        remark: '',
        id: ''
      }
    },
    // __token__令牌
    async __token__() {
      const { data: res } = await this.$http.get(
        'index.php/api/index/formtoken'
      )
      if (res.code !== 1) return this.$message.error(res.msg)
      this.token = res.data.info
    },
    // 列表
    async index() {
      const { data: res } = await this.$http.get('edulogin.php/resources/category/index', {
        params: {
          page: this.page,
          limit: this.limit,
        },
      })
      if (res.code != 1) return this.$message.error(res.msg)
      this.indexList = res.data.list
      this.total = res.data.total
    },

    // 查询
    chaxun() {
      this.page = 1
      this.index()
    },
    // 点击添加按钮，弹出添加弹出框
    add() {
      this.text = '添加'
      this.dialogVisible = true
      this.__token__()
    },

    // 添加确认
    add_btn() {
      this.$refs.form.validate(async (valid) => {
        if (!valid) return this.$message.error('请填写完整的数据')
        this.addform.__token__ = this.token
        const { data: res } = await this.$http.post(
          'edulogin.php/resources/category/add',
          this.addform
        )
        if (res.code != 1) {
          this.token = res.data.__token__
          return this.$message.error(res.msg)
        }
        this.$message.success(res.msg)
        this.dialogVisible = false
        this.$refs.form.resetFields()
        ;(this.addform = {
          category_name: '',
          remark: '',
          id: ''
        }),
          this.index()
      })
    },
    // 点击编辑
    edit(row) {
      this.text = '编辑'
      this.id = row.id
      this.dialogVisible = true
      this.addform = {
        category_name: row.category_name,
        remark: row.remark,
        id: row.id
      }
      this.__token__()
    },
    // 编辑确认
    async edit_btn() {
      this.addform.__token__ = this.token
      const { data: res } = await this.$http.post(
        'edulogin.php/resources/category/editcategory',
        this.addform
      )
      if (res.code !== 1) {
        this.token = res.data.__token__
        return this.$message.error(res.msg)
      }
      this.$message.success(res.msg)
      this.$refs.form.resetFields()
      this.dialogVisible = false
      ;(this.addform = {
          category_name: '',
          remark: '',
          id: ''
      }),
        (this.id = '')
      this.index()
    },
    // 删除
    del(id) {
      this.$confirm('此操作将删除该文件, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          const { data: res } = await this.$http.get(
            'edulogin.php/resources/category/delcategory',
            {
              params: {
                id: id
              }
            }
          )
          if (res.code != 1) return this.$message.error(res.msg)
          this.$message.success(res.msg)
          this.index()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    }
  },
}
</script>
<style lang="less" scoped>
.content {
  width: 100%;
  background-color: #fff;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  .tt {
    font-size: 24px;
  }
  .xian {
    width: 100%;
    height: 2px;
    background-color: #1ca6c8;
    margin: 30px auto;
  }
  .list_box {
    width: 100%;
    height: 38px;
    background-color: #f0f0f0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 10px;
    box-sizing: border-box;
    .tt1 {
      font-size: 16px;
      padding: 2px 10px;
      border-left: 3px solid #1ca6c8;
    }
  }
  .int_box1 {
    padding: 0 1px 15px 1px;
  }
  .int_box {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    padding: 15px 1px 0px;
  }
  .int_box_a {
    padding-bottom: 15px;
  }
  .int_width {
    width: 242px;
    margin-right: 40px;
  }
  span {
    color: #666;
  }
  .pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding: 15px 0;
  }
  .xian2 {
    width: 100%;
    height: 2px;
    background-color: #f0f0f0;
    margin-bottom: 20px;
  }
  .ttright {
    font-size: 14px;
    margin-left: 20px;
    color: #666;
  }
  .add_btn {
    width: 140px;
    margin: 40px 0 30px 420px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
    border: 1px dashed #d9d9d9 !important;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
  .list_img {
    width: 40px;
    height: 40px;
    display: block;
  }
}
</style>
